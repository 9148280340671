<template functional>
  <div class="dialog-response-info">
    <div class="mb-3 text-center dialog-response-info__icon-wrapper">
      <SvgIcon :icon="props.icon" />
    </div>
    <div class="text-center dialog-response-info__content">
      <p v-if="props.capitalize">{{ props.content | capitalize }}</p>
      <p v-else v-html="props.content"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DialogResponseInfo',
  props: {
    icon: {
      type: String,
      default: 'list',
    },
    capitalize: Boolean,
    content: String,
  },
};
</script>

<style lang="scss" scoped>
.dialog-response-info__icon {
  width: 50px;
  height: 50px;
  object-fit: contain;
}
.dialog-response-info__content {
  font-size: 1.86rem;
  color: $text-semi-darkest;
  p {
    margin: 0px;
  }
}
</style>
